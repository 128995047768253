import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { mapLocaleCodeToCountry } from '@features/WordPress/utilities/helpers/locale';
import router from 'next/router';
import {
  AddLicensePlateRequest,
  AddNewLicensePlateResponse,
  GetIosResponse,
  PipStartRequest,
  PipStartResponse,
} from '@shared/types';
import {
  MembershipsData,
  ServiceUnitDataType,
  ServiceUnitsAtLocation,
} from '@shared/types/pip';

export const pipApi = createApi({
  reducerPath: 'pipApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.NEXT_PUBLIC_PIP_API_ENDPOINT}/v1/`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as any;
      const { token } = state.auth;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        // headers.set('authorization', token);
      }

      // assume JSON
      headers.set('Content-Type', 'application/json');
      headers.set('accept', '*/*');

      headers.set('apilang', mapLocaleCodeToCountry(router.locale));

      return headers;
    },
  }),
  endpoints: (builder) => ({
    /**
     * Check service status
     */
    checkService: builder.query<ServiceUnitDataType, string>({
      query: (uid) => `pip/${uid}/check`,
    }),

    /**
     * Start service
     */
    startService: builder.mutation<PipStartResponse, PipStartRequest>({
      query: (body) => ({
        url: 'pip/start',
        method: 'POST',
        body,
      }),
    }),

    /**
     * Stop service
     */
    stopService: builder.mutation({
      query: (body) => ({
        url: 'pip/stop',
        method: 'POST',
        body,
      }),
    }),

    /**
     * Add licensePlate to user
     */
    addLicensePlate: builder.mutation<
      AddNewLicensePlateResponse,
      AddLicensePlateRequest
    >({
      query: (body) => ({
        url: 'user/new-lpn',
        method: 'POST',
        body,
      }),
      transformResponse: (response: any) => response?.result || response,
    }),

    /**
     * Get hoses/ios
     */
    getIos: builder.query<GetIosResponse[], string>({
      query: (uid) => `pip/${uid}/get-ios`,
    }),

    /**
     * Get memberships
     */
    getMembershipsPip: builder.query<MembershipsData[], null>({
      query: () => `user/memberships`,
    }),

    getServiceUnitsByLocation: builder.query<ServiceUnitsAtLocation[], string>({
      query: (uid) => `service-units/location/${uid}`,
    }),

    /**
     * Get session check
     */
    checkSession: builder.mutation({
      query: (body) => ({
        url: 'pip/check-session',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useCheckServiceQuery,
  useStartServiceMutation,
  useStopServiceMutation,
  useAddLicensePlateMutation,
  useGetIosQuery,
  useGetMembershipsPipQuery,
  useGetServiceUnitsByLocationQuery,
  useCheckSessionMutation,
} = pipApi;
