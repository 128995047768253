import { ServiceUnitType } from './common';

export interface CreateUser {
  email: string;
  licensePlate?: string;
  conditions: UserConditions;
  password?: string;
  phone?: string;
}

export interface UserConditions {
  termsOfUse: boolean;
  marketingConsent?: boolean;
}

export interface LoginUser {
  email: string;
  password: string;
}

export enum PIPFlowSteps {
  AUTHENTICATION = 'authentication',
  PAYMENT = 'payment',
  WASHING = 'washing',
}

export enum ServiceStates {
  IS_AVAILABLE = 0,
  IS_BUSY = 1,
  IS_WASHING = 2,
  IS_CLOSED = 3,
  IS_ERROR = 4,
  CLOSED_DURING_WASH = 5,
}

export enum PipStatus {
  NotValid = 'Not a valid service',
  Closed = 'Service closed',
  InUse = 'Service already in use',
  Available = 'OK',
}

export enum PipEvents {
  START = 'start',
  STOP = 'stop',
  CLOSED = 'closed',
  PING = 'ping',
  BUTTON_PRESS = 'buttonPress',
  PAYMENT_FAILED = 'payment_failed',
}

export enum PIPModals {
  HELP = 'helpModal',
}

export interface ModalType {
  type: PIPModals;
  isOpen: boolean;
}

export enum PaymentMethodTypes {
  AMEX = 'amex',
  MAESTRO = 'maestro',
  MC = 'mc',
  VISA = 'visa',
  VISADANKORT = 'visadankort',
  DANKORT = 'dankort',
  MC_APPLEPAY = 'mc_applepay',
  VISA_APPLEPAY = 'visa_applepay',
  VISA_GOOGLEPAY = 'visa_googlepay',
  MC_GOOGLEPAY = 'mc_googlepay',
  GOOGLEPAY = 'googlepay',
  PAYPAL = 'paypal',
}

export const PaymentLogos = {
  [PaymentMethodTypes.MC]: '/assets/icons/mastercard.svg',
  [PaymentMethodTypes.VISA]: '/assets/icons/visa-blue.svg',
  [PaymentMethodTypes.VISADANKORT]: '/assets/icons/visa-blue.svg',
  [PaymentMethodTypes.DANKORT]: '/assets/icons/dankort.svg',
  [PaymentMethodTypes.VISA_APPLEPAY]: '/assets/icons/applepay-simple.svg',
  [PaymentMethodTypes.MC_APPLEPAY]: '/assets/icons/applepay-simple.svg',
  [PaymentMethodTypes.VISA_GOOGLEPAY]: '/assets/icons/google-pay.svg',
  [PaymentMethodTypes.GOOGLEPAY]: '/assets/icons/google-pay.svg',
  [PaymentMethodTypes.PAYPAL]: '/assets/icons/paypal-icon.png',
};

export interface LicensePlateType {
  membership: string;
  name: string;
  value: string;
}

export interface AddNewCar {
  licensePlate: string;
}

export interface IosResponse {
  bus_address: number;
  color: string;
  created_at: Date;
  fc: number;
  name: string;
  uid: string;
  updated_at: Date;
}

interface NextAvailablePeriods {
  start: Date;
  end: Date;
}

interface Pause {
  nextAvailablePeriods: NextAvailablePeriods[];
  periodsPaused: any[];
  remainingPeriods: number;
}

interface LicensePlate {
  changeAble: boolean;
  changeable_date: Date | null;
  last_change: Date | null;
  plate: string;
  uid: string;
}

export interface Memberships {
  has_atav: boolean;
  is_editable: boolean;
  is_expiring: boolean;
  is_pre_order: boolean;
  is_single_wash: boolean;
  is_terminated: boolean;
  start_date: Date;
  status: number;
  status_string: string;
  uid: string;
  location: Location;
  license_plate: LicensePlate;
  pause: Pause;
}

export interface ServiceUnitDataType {
  status: PipStatus;
  serviceUnit: string;
  price: number;
  locationUid: string;
  locationName: string;
  serviceMessages: string[];
  outOfOrder: boolean;
  serviceUnits: ServiceUnit[];
  session?: string;
  membership?: string;
  startTime?: string;
  name?: string;
  type: number;
}

export interface ServiceUnit {
  status: PipStatus;
  uid: string;
  name: string;
  type: ServiceUnitType;
  model: string;
  outOfOrder?: boolean;
  membership?: string;
  session?: string;
  startTime?: string;
}

export interface SelectedServiceUnit {
  status: PipStatus;
  uid: string;
  serviceUnit: string;
  serviceUnitId: string;
  type: ServiceUnitType;
  model: string;
  outOfOrder: boolean;
  pipClosed: boolean;
  membership?: string;
  session?: string;
  startTime?: string;
  serviceUnits?: ServiceUnit[];
  locationUid?: string;
  serviceMessages?: string[];
}

export interface ServiceUnitsAtLocation {
  uid: string;
  unitName: string;
  status: number;
  model: string;
  type: string;
  location: string;
  roofbox: number | null;
  number: number | null;
  service_unit_id: number;
  opening_date: string | null;
  pip: string;
  pip_uid: string;
  pip_closed: number;
  locationName: string;
  location_uid: string;
  Country_id: string;
  group: number | null;
}

export interface PipData {
  uid: string;
  membership: string;
  startTime: string;
  minutes: number;
  price: number;
  session: string;
  serviceUnits?: ServiceUnit[];
  status: PipStatus;
  serviceUnit: string;
  type: ServiceUnitType;
  model: string;
  outOfOrder: boolean;
  pipClosed: boolean;
  button?: 'stop_in';
}

export enum LocationUid {
  SØBORG = 'Locations5b8915b64748b',
}

export interface PipCurrentStatus {
  text: string;
  color: string;
  state: number;
}

export enum PIPVersions {
  PIP = 'pip',
  PIP2 = 'pip2',
}

export enum UsedPIPVersion {
  VERSION = 'pip',
}

export interface LicensePlates {
  uid: string;
  plate: string;
}

export interface MembershipsData {
  uid: string;
  license_plate: LicensePlates;
  start_date: string | null;
  status: number;
  status_string: string;
}

export interface LicensePlateData {
  name: string;
  value: string;
  membership: string;
}

export enum LocalStorageKeys {
  CHECKED_SESSION = 'checkedSession',
}
