/**
 * Maps the next.config.js language locale (e.g.) domain.com/da to the locale
 * code in WordPress and vice versa
 *
 * /da = DA
 * /de = DE
 * /se = SV
 * /no = NB
 */

/**
 * country -> locale
 *
 * /da = DA
 * /de = DE
 * /se = SV
 * /no = NB
 *
 * @param   {string}  country  e.g. "se"
 *
 * @return  {Locale}           e.g. "SV"
 */
export const mapCountryToLocaleCode = (country) => {
  const map = {
    da: 'DA',
    de: 'DE',
    se: 'SV',
    no: 'NB',
  };

  if (!country) {
    // eslint-disable-next-line no-console
    console.warn('[mapCountryToLocaleCode] Called without country parameter');
  }

  return map?.[country?.toString().toLocaleLowerCase()] ?? (country || 'DA');
};

/**
 * locale -> country
 */
export const mapLocaleCodeToCountry = (locale) => {
  const map = {
    da: 'dk',
    de: 'de',
    sv: 'se',
    nb: 'no',
  };
  return map?.[locale.toString().toLocaleLowerCase()] ?? locale;
};

/**
 * locale -> zipcode
 */
export const mapLocaleCodeToZipCodeLength = (locale) => {
  const map = {
    da: {
      zipcodeLength: '4',
      placeholder: '2813',
    },
    de: {
      zipcodeLength: '5',
      placeholder: '28136',
    },
    se: {
      zipcodeLength: '5',
      placeholder: '281 36',
    },
    no: {
      zipcodeLength: '4',
      placeholder: '2813',
    },
  };
  return map?.[locale.toString().toLocaleLowerCase()] ?? locale;
};

/**
 * locale -> license plate length
 */
export const mapLocaleCodeToPlate = (locale) => {
  const map = {
    da: {
      min: 2,
      max: 8,
      placeholder: 'AB12345',
    },
    de: {
      min: 3,
      max: 8,
      placeholder: 'ABCDE123',
    },
    se: {
      min: 2,
      max: 7,
      placeholder: 'ABC123',
    },
    no: {
      min: 2,
      max: 7,
      placeholder: 'AB12345',
    },
  };
  return map?.[locale.toString().toLocaleLowerCase()] ?? locale;
};

export const mapLocaleCodeToVat = (locale) => {
  const map = {
    da: {
      vatLength: 8,
      placeholder: '12345678',
    },
    de: {
      vatLength: [9, 14],
      placeholder: '123456789',
    },
    se: {
      vatLength: 12,
      placeholder: '123456789123',
    },
    no: {
      vatLength: 9,
      placeholder: '123456789',
    },
  };
  return map?.[locale.toString().toLocaleLowerCase()] ?? locale;
};

export const mapLocaleCodeToLocation = (locale) => {
  const map = {
    da: {
      locationsAmount: 130,
    },
    de: {
      locationsAmount: 1,
    },
    se: {
      locationsAmount: 35,
    },
    no: {
      locationsAmount: 8,
    },
  };
  return map?.[locale.toString().toLocaleLowerCase()] ?? locale;
};
